// /* eslint-disable */
import React, {Component} from "react";
import {
    Card,
    Row,
    Col,
    Form,
    InputNumber,
    Select,
    Button,
    message,
    DatePicker,
    Input, Divider, Switch, Table
} from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import moment from 'moment';
import {DeleteOutlined, EditOutlined, PlusOutlined, RightOutlined} from "@ant-design/icons";
import {Redirect} from "react-router-dom";

const { Option } = Select;

export default class AddTransactionForm extends Component {

  constructor(props) {
    super(props);
    this.state = {

      customerOptions: [],
        tableData: [],
        tableDataForBill: [],
        itemMaxQuantity: 999999999,
        currentItemName: '',
        showOverMaxQuantityWarning: false,
        currentItemCode: '',
      ReferenceOptions: [],
      type: 'Selling',
        Unit: 'Unit',
        method: 'Cash',
        discount: false,
        itemSubTotal: 0,

    };
  }

  formRef = React.createRef();



  handleCalculateSubTotal = () => {
      //get SUB_TOTAL value and apply discount if available
        const form = this.formRef.current;
        if (form && this.state.discount && this.state.itemSubTotal) {
            const subTotal = this.state.itemSubTotal;
            const discount = form.getFieldValue('DISCOUNT') || 0;

            if(form.getFieldValue('DISCOUNT_TYPE') === 'Percentage'){
                const total = subTotal - (subTotal * discount / 100);
                form.setFieldsValue({ SUB_TOTAL: total.toFixed(2) });
                this.handleCalculateBalance();
            }
            else {
                const total = subTotal - discount;
                form.setFieldsValue({SUB_TOTAL: total.toFixed(2)});
                this.handleCalculateBalance();
            }
        }
        else {
            form.setFieldsValue({ SUB_TOTAL: this.state.itemSubTotal.toFixed(2) });
            this.handleCalculateBalance();
        }
  }

  handleCalculateTotal = () => {
    const form = this.formRef.current;
    if (form) {
      const soldAmount = form.getFieldValue('AMOUNT') || 0;
      const sharePercentage = form.getFieldValue('SHARE_PERCENTAGE') || 0;

      const total = soldAmount * sharePercentage / 100;

      form.setFieldsValue({ SHARE_VALUE: total });
    }
  };

  handleTransactionTypeChange = async (value) => {
    this.setState({ type: value , tableData: [] });
    const form = this.formRef.current;
    form.resetFields(['ITEM', 'PRICE', 'QUANTITY', 'TOTAL', 'SUB_TOTAL']);
    form.getFieldInstance('ITEM').focus();
    await this.fetchReferenceOptions();
    this.handleCalculateBalance();
  }



  async componentDidMount() {
      const form = this.formRef.current;

      // Fetch customer options when the component mounts
    // const customerOptions = await this.fetchCustomerOptions();
    // this.setState({ customerOptions });
    const ReferenceOptions = await this.fetchReferenceOptions();
    this.setState({ ReferenceOptions });
      form.getFieldInstance('ITEM').focus();
      document.addEventListener('keydown', this.handleKeyDown);

  }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (e) => {
        // Check if both Ctrl and Enter keys are pressed
        if (e.ctrlKey && e.key === 'Enter') {
            e.preventDefault(); // Prevent the default behavior (e.g., new line in text area)
            this.handleSubmit(); // Call your form submission function
        }
        // go to ITEM field when Ctrl + I is pressed
        if (e.ctrlKey && e.key === 'i') {
            e.preventDefault(); // Prevent the default behavior (e.g., new line in text area)
            this.formRef.current.getFieldInstance('ITEM').focus(); // Call your form submission function
        }
        // go to PRICE field when Ctrl + P is pressed
        if (e.ctrlKey && e.key === 'p') {
            e.preventDefault(); // Prevent the default behavior (e.g., new line in text area)
            this.formRef.current.getFieldInstance('PRICE').focus(); // Call your form submission function
        }
        // go to QUANTITY field when Ctrl + Q is pressed
        if (e.ctrlKey && e.key === 'q') {
            e.preventDefault(); // Prevent the default behavior (e.g., new line in text area)
            this.formRef.current.getFieldInstance('QUANTITY').focus(); // Call your form submission function
        }
        // go to TOTAL field when Ctrl + T is pressed
        if (e.ctrlKey && e.key === 't') {
            e.preventDefault(); // Prevent the default behavior (e.g., new line in text area)
            this.formRef.current.getFieldInstance('TOTAL').focus(); // Call your form submission function
        }
        // go to SUB_TOTAL field when Ctrl + S is pressed
        if (e.ctrlKey && e.key === 's') {
            e.preventDefault(); // Prevent the default behavior (e.g., new line in text area)
            this.formRef.current.getFieldInstance('SUB_TOTAL').focus(); // Call your form submission function
        }

    };

  // async fetchCustomerOptions() {
  //       try {
  //           const response = await axios.post("https://iontheway.store/api/getAllCustomers");
  //           //console.log("response", response);
  //
  //           return response.data.result.map((customer) => ({
  //               value: customer.CUSTOMER_ID,
  //               label: customer.NAME,
  //           }));
  //       } catch (error) {
  //           console.error("Error fetching customer options:", error);
  //           return [];
  //       }
  //   }

  async fetchReferenceOptions1() {
    try {
      const response = await axios.post("https://iontheway.store/api/getItemsForReference");
      console.log("response", response);
      this.state.ReferenceOptions = response.data.result.map((ref) => ({
              value: ref.ITEM_ID,
              code: ref.CODE,
              label: ref.STOCK > 0 ? `${ref.CODE} - ${ref.NAME} - ( ${ref.STOCK} )` : `${ref.CODE} - ${ref.NAME} - (Out of Stock)`,
      }
        ));
    } catch (error) {
      console.error("Error fetching reference options:", error);
      return [];
    }
  }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post("https://iontheway.store/api/getItemsForReference");
            console.log("response", response);
            return response.data.result.map((ref) => ({
                value: ref.ITEM_ID,
                code: ref.CODE,
                label: ref.STOCK > 0 ? `${ref.CODE} - ${ref.NAME} - ( ${ref.STOCK} )` : `${ref.CODE} - ${ref.NAME} - (Out of Stock)`,
            }));
        } catch (error) {
            console.error("Error fetching reference options:", error);
            return [];
        }
    }

    handleCalculateTotal = () => {
        const form = this.formRef.current;
        if (form) {
            const price = form.getFieldValue('PRICE') || 0;
            const quantity = form.getFieldValue('QUANTITY') || 0;

            const total = price * quantity;
            //limit to 2 decimal places
            const Fixedtotal = total.toFixed(2);

            form.setFieldsValue({ TOTAL: Fixedtotal });
            if(quantity > this.state.itemMaxQuantity){
                this.setState({ showOverMaxQuantityWarning: true });
            } else {
                this.setState({ showOverMaxQuantityWarning: false });
            }
        }
    }



  handleReferenceChange = async (value) => {
    //console.log(`selected ${value}`);
    const form = this.formRef.current;
    try {
      const response = await axios.post('https://iontheway.store/api/getItemsDetailsForTransaction', {
        ITEM_ID: value,
      });

      if (response.data.success) {
        //console.log("response", response);
          if(this.state.type === 'Selling'){
              form.setFieldsValue({ PRICE: response.data.result.SELLING_PRICE });
          }
            else{
                form.setFieldsValue({ PRICE: response.data.result.BUYING_PRICE });
          }

        this.setState({
            itemMaxQuantity: response.data.result.STOCK,
            currentItemName: response.data.result.NAME,
            currentItemCode: response.data.result.CODE,
            Unit: response.data.result.UNIT,
            showOverMaxQuantityWarning: false,
        }
        );
        form.resetFields(['QUANTITY', 'TOTAL']);
        //focus on QUANTITY field
        form.getFieldInstance('QUANTITY').focus();


      } else {
        message.error('Failed to fetch Item Details');
      }
    } catch (error) {
        console.error('Error fetching Item Details:', error);
        message.error('Internal server error');
    }
  };
    // handleKeyDown = (e) => {
    //     // Check if the Enter key is pressed
    //     if (e.key === 'Enter') {
    //         // Check if the Ctrl key is pressed
    //         if (e.ctrlKey) {
    //             // Prevent the default Enter key behavior
    //             e.preventDefault();
    //
    //             // Manually trigger the form submission
    //             this.formRef.current.submit();
    //         } else {
    //             // Prevent form submission on Enter key press without Ctrl
    //             e.stopPropagation();
    //         }
    //     }
    // };

    handleAddItemToTable = async () => {
        const form = this.formRef.current;
        try {

            if (form.getFieldValue('ITEM') !== undefined && form.getFieldValue('PRICE') !== undefined && form.getFieldValue('QUANTITY') !== undefined && form.getFieldValue('TOTAL') !== undefined) {

                const currentItem = {
                    ITEM_ID: form.getFieldValue('ITEM'),
                    ITEM_CODE: this.state.currentItemCode,
                    ITEM_NAME: this.state.currentItemName,
                    PRICE: parseFloat(form.getFieldValue('PRICE')),
                    QUANTITY: parseFloat(form.getFieldValue('QUANTITY')),
                    TOTAL: parseFloat(form.getFieldValue('TOTAL')),
                };

                form.resetFields(['DUE_AMOUNT']);

                // this.setState({ tableDataForBill: [...this.state.tableDataForBill, currentItem] });

                // const existingItemIndex = this.state.tableData.findIndex(
                //     (item) => item.ITEM_ID === currentItem.ITEM_ID && item.PRICE === currentItem.PRICE
                // );
                //
                // if (existingItemIndex !== -1) {
                //     // If the item with the same ITEM_ID and PRICE exists, update its QUANTITY and TOTAL
                //     const updatedTableData = [...this.state.tableData];
                //     updatedTableData[existingItemIndex].QUANTITY += currentItem.QUANTITY;
                //     updatedTableData[existingItemIndex].TOTAL += currentItem.TOTAL;
                //     await this.setState({tableData: updatedTableData});
                // } else {
                //     // If the item with the same ITEM_ID and PRICE doesn't exist, add a new row
                //     await this.setState((prevState) => ({tableData: [...prevState.tableData, currentItem]}));
                // }

                await this.setState((prevState) => ({tableData: [...prevState.tableData, currentItem]}));


                form.resetFields(['ITEM', 'PRICE', 'QUANTITY', 'TOTAL']);

                form.getFieldInstance('ITEM').focus();
                form.setFieldsValue({ITEM: undefined});


                // Calculate Sub Total and update the form
                const subTotal = this.state.tableData.reduce((acc, item) => acc + Number(item.TOTAL), 0);
                this.state.itemSubTotal = subTotal;
                form.setFieldsValue({SUB_TOTAL: subTotal.toFixed(2)});
                this.handleCalculateSubTotal();
                this.handleCalculateBalance();
                console.log('subTotal', subTotal);
                console.log('tableData', this.state.tableData);


            } else {
                message.error('Please fill all the fields');
            }
        }
        catch (error) {
            console.error('Error adding item to table:', error);
            message.error('Internal server error');
        }
    };


    handleSubmit = async () => {
        const form = this.formRef.current;
        try {
            // Validate and get form values
            const values = await form.validateFields();

            // Retrieve USER_ID from rememberedUser
            const rememberedUser = Cookies.get('rememberedUser');
            const USER_ID = rememberedUser ? JSON.parse(rememberedUser).USER_ID : null;

            const transactionData = {
                ...values,
                CREATED_BY: USER_ID,
                ITEMS: this.state.tableData,
            };

            console.log('Transaction Data:', transactionData);

            const response = await axios.post('https://iontheway.store/api/addTransaction', transactionData);

            if (response.data.success) {
                message.success('Transaction added successfully');
                //print bill
                const response2 = await axios.post('https://iontheway.store/api/generateBillByGenerator', { TRANSACTION_ID: response.data.transactionId });
                if (response2.data.success) {
                    console.log('response2', response2);
                    const pdfData = response2.data.data;
                    const pdfBlob = new Blob([Buffer.from(pdfData, 'base64')], { type: 'application/pdf' });
                    const pdfUrl = URL.createObjectURL(pdfBlob);

                    console.log('response113');

                    // Create an iframe to load the PDF
                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    iframe.src = pdfUrl;
                    document.body.appendChild(iframe);

                    // Print the PDF
                    iframe.contentWindow.print();

                    // Remove the iframe after printing
                    iframe.onload = function () {
                        setTimeout(() => {
                            document.body.removeChild(iframe);
                        }, 90000); // Adjust the timeout as needed
                    };
                    console.log('response112');
                } else {
                    message.error('Failed to generate invoice');
                    console.error('Error generating invoice:', response.data.message);
                }
                // this.handlePrintBill(response.data.transactionId);
                this.formRef.current.resetFields();
                this.setState({ tableData: [] });
                await this.fetchReferenceOptions1();
                this.setState({ method: 'Cash' , discount: false });
            } else {
                message.error('Failed to add Transaction');
            }
            form.getFieldInstance('ITEM').focus();
        } catch (error) {
            console.error('Error submitting transaction:', error);
            message.error('Internal server error');
        }
    };

    handlePrintBill = async (transactionId) => {
        console.log('transactionId', transactionId);
        try {
            const response = await axios.post('https://iontheway.store/api/generateBillByGenerator', {
                TRANSACTION_ID: transactionId,
            });

            if (response.data.success) {
                const pdfData = response.data.data;
                const pdfBlob = new Blob([Buffer.from(pdfData, 'base64')], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);

                // Create an iframe to load the PDF
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = pdfUrl;
                document.body.appendChild(iframe);

                // Print the PDF
                iframe.contentWindow.print();

                // Remove the iframe after printing
                iframe.onload = function () {
                    setTimeout(() => {
                        document.body.removeChild(iframe);
                    }, 90000); // Adjust the timeout as needed
                };
            } else {
                console.error('Error generating invoice:', response.data.message);
                // Handle error, e.g., display an error message
            }
        } catch (error) {
            console.error('Error generating invoice:', error.message);
            // Handle error, e.g., display an error message
        }
    }

    // handleSubmit = async () => {
    //     const form = this.formRef.current;
    //     try {
    //         // Validate and get form values
    //         const values = await form.validateFields();
    //
    //         // Retrieve USER_ID from rememberedUser
    //         const rememberedUser = Cookies.get('rememberedUser');
    //         const USER_ID = rememberedUser ? JSON.parse(rememberedUser).USER_ID : null;
    //
    //         const transactionData = {
    //             ...values,
    //             CREATED_BY: USER_ID,
    //             ITEMS: this.state.tableData,
    //         };
    //
    //         console.log('Transaction Data:', transactionData);
    //
    //         const response = await axios.post('https://iontheway.store/api/addTransaction', transactionData);
    //
    //         if (response.data.success) {
    //             message.success('Transaction added successfully');
    //             //print bill
    //             const transactionId = response.data.transactionId;
    //             const response2 = await axios.post('https://iontheway.store/api/generateBillByGenerator', { TRANSACTION_ID: transactionId });
    //             if (response2.data.success) {
    //                 const pdfData = response2.data.data;
    //                 const pdfBlob = new Blob([Buffer.from(pdfData, 'base64')], { type: 'application/pdf' });
    //                 const pdfUrl = URL.createObjectURL(pdfBlob);
    //
    //                 // Create an iframe to load the PDF
    //                 const iframe = document.createElement('iframe');
    //                 iframe.style.display = 'none';
    //                 iframe.src = pdfUrl;
    //                 document.body.appendChild(iframe);
    //
    //                 // Print the PDF
    //                 iframe.contentWindow.print();
    //
    //                 // Remove the iframe after printing
    //                 iframe.onload = function () {
    //                     setTimeout(() => {
    //                         document.body.removeChild(iframe);
    //                     }, 15000); // Adjust the timeout as needed
    //                 };
    //             } else {
    //                 message.error('Failed to generate invoice');
    //                 console.error('Error generating invoice:', response.data.message);
    //             }
    //             this.formRef.current.resetFields();
    //             this.setState({ tableData: [] });
    //             await this.fetchReferenceOptions1();
    //             this.setState({ showOverMaxQuantityWarning: false });
    //         } else {
    //             message.error('Failed to add Transaction');
    //         }
    //         form.getFieldInstance('ITEM').focus();
    //     } catch (error) {
    //         console.error('Error submitting transaction:', error);
    //         message.error('Internal server error');
    //     }
    // };



    handleDeleteItem = async (record) => {
        await this.setState((prevState) => ({
            tableData: prevState.tableData.filter((item) => item !== record),
        }));

        // Calculate Sub Total and update the form
        const form = this.formRef.current;
        if (form) {
            const subTotal = this.state.tableData.reduce((acc, item) => acc + Number(item.TOTAL), 0);
            this.state.itemSubTotal = subTotal;
            form.setFieldsValue({ SUB_TOTAL: subTotal.toFixed(2) });
            this.handleCalculateSubTotal();
            this.handleCalculateBalance();
        }
    }

    handleCalculateBalance = () => {
        const form = this.formRef.current;
        if (form) {
            const total = form.getFieldValue('GIVEN_AMOUNT') || 0;
            const paid = form.getFieldValue('SUB_TOTAL') || 0;

            const balance = total - paid;
            form.setFieldsValue({ BALANCE: balance.toFixed(2) });
        }
    }

    handleEditItem = async (record) => {
        const form = this.formRef.current;
        if (form) {
            form.setFieldsValue({
                ITEM: record.ITEM_ID,
                PRICE: record.PRICE,
                QUANTITY: record.QUANTITY,
                TOTAL: record.TOTAL,
            });

            await this.setState((prevState) => ({
                tableData: prevState.tableData.filter((item) => item !== record),
            }));

            // Calculate Sub Total and update the form
            const subTotal = this.state.tableData.reduce((acc, item) => acc + Number(item.TOTAL), 0);
            this.state.itemSubTotal = subTotal;
            form.setFieldsValue({ SUB_TOTAL: subTotal.toFixed(2) });

            this.handleCalculateSubTotal();
            this.handleCalculateBalance();

            this.setState({
                currentItemName: record.ITEM_NAME,
                currentItemCode: record.ITEM_CODE,
                itemMaxQuantity: record.QUANTITY,
                showOverMaxQuantityWarning: false,
            });
        }
    }



  render() {
    const inputStyle = {
      width: '100%',
      height: '30px',
    };

      let rememberedUser = Cookies.get('rememberedUser');
      let ROLE = null;

      if (rememberedUser) {
          rememberedUser = JSON.parse(rememberedUser);
          ROLE = rememberedUser.ROLE;
      }

      if(ROLE === 'USER_MILL'){
          return <Redirect to="/dashboard" />
      }

    const {   tableData,ReferenceOptions,type } = this.state;


    return (

        <>
          <div className="tabled">
            <Row gutter={[16, 16]} justify="left" align="top">
              <Col xs="24" xl={24}>
                <Card
                    className="criclebox tablespace mb-24"
                    // title="Add New Transaction"
                >
                  <Form
                      layout="vertical"
                      onFinish={this.handleSubmit}
                      style={{ margin: '20px' }}
                      ref={this.formRef}

                  >
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name="TYPE"
                                label="Transaction Type"
                                initialValue='Selling'
                                rules={[{ required: true, message: 'Please select Transaction Type' }]}
                            >
                                <Select placeholder="Select Transaction Type" allowClear showSearch onChange={this.handleTransactionTypeChange}>
                                    <Option value="Selling">Selling</Option>
                                    <Option value="Buying">Buying</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {/*{type === 'Selling' ? (*/}
                        {/*<Col xs={24} sm={24} md={24} lg={6}>*/}
                        {/*    <Form.Item*/}
                        {/*        name="CUSTOMER"*/}
                        {/*        label="Customer"*/}
                        {/*        // rules={[{ required: true, message: 'Please select Customer' }]}*/}
                        {/*    >*/}
                        {/*        <Select placeholder="Select Customer" allowClear showSearch>*/}
                        {/*            {this.state.customerOptions.map((option) => (*/}
                        {/*                <Option key={option.value} value={option.value} title={option.label}>*/}
                        {/*                    {option.label}*/}
                        {/*                </Option>*/}
                        {/*            ))}*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/*) : null }*/}
                        {/*{type === 'Selling' ? (*/}
                        <Col xs={24} sm={24} md={24} lg={3}>
                            <Form.Item
                                name="METHOD"
                                label="Method"
                                rules={[{ required: true, message: 'Please select Payment Method' }]}
                                initialValue="Cash"
                            >
                                <Select placeholder="Select Payment Method" allowClear showSearch onChange={(value) => this.setState({ method: value })}>
                                    <Option value="Cash">Cash</Option>
                                    <Option value="Card">Card</Option>
                                    {this.state.type === 'Buying' ? (
                                        <Option value="Credit">Credit</Option>
                                    ) : null }
                                    {this.state.type === 'Buying' ? (
                                        <Option value="Cheque">Cheque</Option>
                                    ) : null }
                                </Select>
                            </Form.Item>
                        </Col>
                        {/*) : null }*/}
                        <Col xs={24} sm={24} md={24} lg={3}>
                            <Form.Item
                                name="DATE"
                                label="Date"
                                initialValue={moment()}
                                rules={[{ required: true, message: 'Please select Transaction Date' }]}
                            >
                                <DatePicker style={inputStyle} placeholder="Select Transaction Date" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name="GIVEN_AMOUNT"
                                label="Given Amount"
                                rules={[{ required: true, message: 'Please enter Given Amount' }]}
                            >
                                <InputNumber
                                    style={inputStyle}
                                    min={0}
                                    step={0.01}
                                    placeholder="Enter Given Amount"
                                    onChange={this.handleCalculateBalance}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name="BALANCE"
                                label="Balance"
                            >
                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Balance" readOnly={true} />
                            </Form.Item>
                        </Col>

                        <Divider />
                    </Row>
                        {this.state.method === 'Cheque' ? (
                            <Row gutter={[16, 16]} justify="left" align="top">
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item
                                    name="CHEQUE_NO"
                                    label="Cheque Number"
                                    rules={[{ required: true, message: 'Please enter Cheque Number' }]}
                                >
                                    <Input style={inputStyle} placeholder="Enter Cheque Number" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item
                                    name="CHEQUE_EXPIRY"
                                    label="Cheque Expiry Date"
                                >
                                    <DatePicker style={inputStyle} placeholder="Select Cheque Expiry Date" />
                                </Form.Item>
                            </Col>
                                <Col xs={24} sm={24} md={24} lg={6}>
                                    <Form.Item
                                        name="CHEQUE_BANK"
                                        label="Cheque Bank"
                                    >
                                        <Input style={inputStyle} placeholder="Enter Cheque Bank" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6}>
                                    <Form.Item
                                        name="IS_CHEQUE_COLLECTED"
                                        label="Cheque Collected"
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>
                                <Divider />
                            </Row>
                        ) : null }
                      {this.state.method === 'Card' ? (
                            <Row gutter={[16, 16]} justify="left" align="top">
                                <Col xs={24} sm={24} md={24} lg={6}>
                                    <Form.Item
                                        name="CHEQUE_NO"
                                        label="Card Transaction ID"
                                        rules={[{ required: true, message: 'Please enter Card Transaction ID' }]}
                                    >
                                        <Input style={inputStyle} placeholder="Enter Card Transaction ID" />
                                    </Form.Item>
                                </Col>
                                <Divider />
                            </Row>
                        ) : null }
                      {this.state.method === 'Credit' ? (
                          <Row gutter={[16, 16]} justify="left" align="top">
                              <Col xs={24} sm={24} md={24} lg={6}>
                                  <Form.Item
                                      name="CHEQUE_EXPIRY"
                                      label="Credit Expiry Date"
                                  >
                                      <DatePicker style={inputStyle} placeholder="Select Credit Expiry Date" />
                                  </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={6}>
                                  <Form.Item
                                      name="IS_CHEQUE_COLLECTED"
                                      label="Cheque Collected"
                                      valuePropName="checked"
                                  >
                                      <Switch />
                                  </Form.Item>
                              </Col>
                              <Divider />
                          </Row>
                      ) : null }

                    <Row gutter={[16, 16]} justify="left" align="top">

                      <Col xs={24} sm={24} md={24} lg={9}>
                        <Form.Item
                            name="ITEM"
                            label="Item"
                            defaultValue={1}
                        >
                          <Select placeholder="Select Item" allowClear showSearch onSelect={this.handleReferenceChange}
                                  filterOption={(input, option) =>
                                      // (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                      // (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                      (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                    }>
                            {ReferenceOptions.map((option) => (
                                <Option key={option.value} value={option.value} title={option.label}>
                                  {option.label}
                                </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                        <Col xs={24} sm={24} md={24} lg={3}>
                            <Form.Item
                                name="PRICE"
                                label={`Price Per ${this.state.Unit}`}
                            >
                                <InputNumber
                                    style={inputStyle}
                                    min={0}
                                    step={0.01}
                                    placeholder="Enter Price"
                                    onChange={this.handleCalculateTotal}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name="QUANTITY"
                                label="Quantity"
                            >
                                <InputNumber
                                    style={inputStyle}
                                    min={0}
                                    placeholder="Enter Quantity"
                                    onChange={this.handleCalculateTotal}
                                    onPressEnter={e => {
                                        e.preventDefault(); // Prevent the default behavior (form submission)
                                        this.handleAddItemToTable(); // Call your custom function
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item
                                name="TOTAL"
                                label="Total (Rs)"
                            >
                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Total" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={1}>
                            <Form.Item
                                label=" "
                                name="ADD_ITEM_TO_TABLE"
                            >
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    onClick={this.handleAddItemToTable}
                                >
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                      {(this.state.showOverMaxQuantityWarning && type === 'Selling') ? <p style={{ color: 'red' }}>Quantity exceeds the available stock</p> : null}
                      <Row gutter={[16, 16]} justify="left" align="top">
                          <Table
                              className="ant-border-space"
                              columns={[
                                  {
                                      title: 'Item Code',
                                      dataIndex: 'ITEM_CODE',
                                  },
                                  {
                                      title: 'Item Name',
                                      dataIndex: 'ITEM_NAME',
                                  },
                                  {
                                      title: 'Price Per Unit',
                                      dataIndex: 'PRICE',
                                        align: 'right',
                                      render: (text, record) => {
                                            return 'Rs ' + record.PRICE.toFixed(2)
                                        }
                                  },
                                  {
                                      title: 'Quantity',
                                      dataIndex: 'QUANTITY',
                                        align: 'right',
                                      render: (text, record) => {
                                            return record.QUANTITY;
                                        }
                                  },
                                  {
                                      title: 'Total (Rs)',
                                      dataIndex: 'TOTAL',
                                      align: 'right',
                                        render: (text, record) => {
                                            return 'Rs ' + record.TOTAL.toFixed(2);
                                        }
                                  },
                                  {
                                      title: 'Action',
                                      dataIndex: 'action',
                                      align: 'center',
                                      render: (_, record) => (
                                          <div style={{ textAlign: 'center' }}>
                                              <Button type="default" onClick={() => this.handleEditItem(record)}>
                                                  <EditOutlined />
                                              </Button>
                                              <Divider type="vertical" />
                                              <Button danger onClick={() => this.handleDeleteItem(record)}>
                                                  <DeleteOutlined />
                                              </Button>
                                          </div>
                                      ),
                                  }

                              ]}
                              dataSource={tableData}
                              rowKey={(record, index) => index}
                              pagination={false}
                              scroll={{ y: 240 }}
                              bordered
                              footer={() => {
                                  // Calculate and display the subtotal
                                  const subTotal = tableData.reduce((acc, item) => acc + Number(item.TOTAL), 0);
                                  return (
                                      <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                          Sub Total (Rs): {subTotal.toFixed(2)}
                                      </div>
                                  );
                              }}
                          />

                      </Row>
                      <Divider />
                        <Row gutter={[16, 16]} justify="left" align="top">
                            <Col xs={24} sm={24} md={24} lg={3}>
                                <Form.Item
                                    name="DISCOUNT_AVAILABLE"
                                    label="Discount Available"
                                    valuePropName="checked"
                                    initialValue={false}
                                >
                                    <Switch
                                        onChange={(value) => {
                                            this.setState({ discount: value }, () => {
                                                this.handleCalculateSubTotal();
                                            });
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            {this.state.discount ? (
                            <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name="DISCOUNT_TYPE"
                                label="Discount Type"
                                initialValue="Percentage"
                                rules={[{ required: this.state.discount, message: 'Please select Discount Type' }]}
                            >
                                <Select placeholder="Select Discount Type"  showSearch onSelect={this.handleCalculateSubTotal}>
                                    <Option value="Percentage">Percentage</Option>
                                    <Option value="Fixed">Fixed</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                            ) : null }
                            {this.state.discount ? (
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name="DISCOUNT"
                                label="Discount"
                                rules={[{ required: this.state.discount, message: 'Please enter Discount' }]}
                            >
                                <InputNumber
                                    style={inputStyle}
                                    min={0}
                                    step={0.01}
                                    placeholder="Enter Discount"
                                    onChange={this.handleCalculateSubTotal}
                                />
                            </Form.Item>
                        </Col>
                            ) : null }
                        </Row>

                      <Row gutter={[16, 16]} justify="left" align="top">

                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name="SUB_TOTAL"
                                label="Sub Total"
                                rules={[{ required: true, message: 'Please enter Sub Total' }]}
                            >
                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Sub Total"/>
                            </Form.Item>
                        </Col>
                          <Col xs={24} sm={24} md={24} lg={18}>
                              <Form.Item
                                  name="COMMENTS"
                                  label="Comments"
                              >
                                  <Input.TextArea rows={2} placeholder="Enter comments" />
                              </Form.Item>
                          </Col>
                      </Row>


                    <Row gutter={[16, 16]} justify="left" align="top">
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Add Transaction
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>

                  </Form>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    );
  }
}
